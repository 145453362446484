<template>
  <div class="">

    <template v-if="method ==='edit'">
      <div class="form-row">
        <sgv-input-text
          class="col-12"
          label="Location"
          v-model="location">
        </sgv-input-text>

        <DetailLocationViewInput
          class="col-12"
          placeholder="เลือก View"
          select="id"
          v-model="selected"
          :templateType="templateType"
          :kioskDeviceType="kioskDeviceType">
        </DetailLocationViewInput>
      </div>

      <button
        type="button"
        class="btn btn-success mb-3"
        @click="addView">
        เพิ่ม
      </button>
    </template>

    <div class="">
      Views:
      <ul>
        <li v-for="junction in junctions" :key="junction.id">
          <fa
            v-if="method ==='edit'"
            icon="trash"
            class="text-danger pointer"
            @click="deleteView(junction)">
          </fa>&nbsp;
          {{junction.location}}: {{junction.view.name}}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import DetailLocationViewInput from './DetailLocationViewInput'
import {
  LIST_JUNCTION,
  CREATE_JUNCTION,
  DESTROY_JUNCTION
} from './graph'

export default {
  props: {
    kioskDeviceId: {
      type: Number,
      required: true
    },
    kioskDeviceType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      junctions: [],
      location: '',
      selected: null
    }
  },
  apollo: {
    junctions: {
      query () {
        return LIST_JUNCTION(this.templateType)
      },
      variables () {
        return {
          kioskDeviceType: this.kioskDeviceType,
          kioskDeviceId: this.kioskDeviceId
        }
      },
      fetchPolicy: 'network-only'
    }
  },
  methods: {
    addView () {
      this.$apollo.mutate({
        mutation: CREATE_JUNCTION(this.templateType),
        variables: {
          kioskDeviceType: this.kioskDeviceType,
          location: this.location,
          kioskDeviceId: this.kioskDeviceId,
          kioskViewId: this.selected
        }
      })
      .then(() => {
        this.$apollo.queries.junctions.refetch()
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    deleteView ({location, kioskDeviceId, kioskViewId}) {
      this.$apollo.mutate({
        mutation: DESTROY_JUNCTION(this.templateType),
        variables: {
          kioskDeviceType: this.kioskDeviceType,
          location,
          kioskDeviceId,
          kioskViewId
        }
      })
      .then(() => {
        this.$apollo.queries.junctions.refetch()
        this.$toasted.global.success("ลบสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    }
  },
  components: {
    DetailLocationViewInput
  }
}
</script>

<style lang="css" scoped>
</style>
