import gql from 'graphql-tag'


export const LIST_VIEW = (templateType) => gql`query LIST_VIEW ($kioskDeviceType: String!, $q: FilterInput) {
  views: listKioskDevice${templateType}View (kioskDeviceType: $kioskDeviceType, q: $q) {
    id type name
  }
}`

export const LIST_JUNCTION = (templateType) => gql`query LIST_JUNCTION ($kioskDeviceType: String!, $kioskDeviceId: Int!) {
  junctions: listKioskDevice${templateType}ViewJunction (kioskDeviceType: $kioskDeviceType, kioskDeviceId: $kioskDeviceId) {
    id location kioskDeviceId kioskViewId
    view {id type name isActive}
  }
}`

export const CREATE_JUNCTION = (templateType) => gql`mutation CREATE_JUNCTION ($kioskDeviceType: String!, $location: String!, $kioskDeviceId: Int!, $kioskViewId: Int!) {
  createJunction: createKioskDevice${templateType}ViewJunction (kioskDeviceType: $kioskDeviceType, location: $location, kioskDeviceId: $kioskDeviceId, kioskViewId: $kioskViewId) {
    id location kioskDeviceId kioskViewId
  }
}`

export const DESTROY_JUNCTION = (templateType) => gql`mutation DESTROY_JUNCTION ($kioskDeviceType: String!, $location: String!, $kioskDeviceId: Int!, $kioskViewId: Int!) {
  destroyJunction: destroyKioskDevice${templateType}ViewJunction (kioskDeviceType: $kioskDeviceType, location: $location, kioskDeviceId: $kioskDeviceId, kioskViewId: $kioskViewId) {
    id location kioskDeviceId kioskViewId
  }
}`
