<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">
          {{title}}
        </h3>
      </div>
    </div>

    <hr class="my-3">

    <sgv-form :method.sync="method" :options="options">

      <div>
        <div class="form-row">
          <sgv-input-text
            :disabled="$auth.disabled(method)"
            class="col-12 col-sm-4"
            label="รหัส"
            v-model="formData.code"
            :validations="[
              {text: 'required!', value: $v.formData.code.$dirty && $v.formData.code.$error}
            ]">
          </sgv-input-text>

          <sgv-input-text
            :disabled="$auth.disabled(method)"
            class="col-12 col-sm-8"
            label="ชื่อ"
            v-model="formData.name">
          </sgv-input-text>

          <sgv-input-text
            :disabled="$auth.disabled(method)"
            class="col-12"
            label="ตำแหน่งการจัดวาง"
            v-model="formData.locations">
          </sgv-input-text>
        </div>

        <div class="form-row">
          <div class="form-group col-12">
            <sgv-input-check
              :disabled="$auth.disabled(method)"
              label="ใช้งาน"
              inline
              v-model="formData.isActive">
            </sgv-input-check>
          </div>
        </div>
      </div>
    </sgv-form>

    <DetailLocation
      v-if="kioskDeviceId > 0"
      :method="method"
      :kioskDeviceId="kioskDeviceId"
      :kioskDeviceType="kioskDeviceType"
      :templateType="templateType">
    </DetailLocation>

  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import DetailLocation from './DetailLocation'
import {
  DETAIL_DEVICE,
  CREATE_DEVICE,
  UPDATE_DEVICE,
  DESTROY_DEVICE,
  RESTART_DEVICE
} from './graph'

export default {
  metaInfo () {
    return {
      title: `${this.subTitle}-${this.formData.code || 'รายการ'}`
    }
  },
  props: {
    templateType: {
      type: String,
      required: true
    },
    kioskDeviceType: {
      type: String,
      required: true
    },
    kioskDeviceId: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      listView: `KioskDevice${this.$form.capitalize(this.kioskDeviceType)}List`,
      formData: {
        code: '',
        name: '',
        locations: '',
        isActive: true
      },
      method: 'info'
    }
  },
  validations: {
    formData: {
      code: {required},
      name: {required},
    }
  },
  computed: {
    options () {
      return [
        {
          text: 'เพิ่ม',
          variant: 'success',
          method: 'add',
          func: this.createData,
          disabled: true
        },
        {
          text: 'แก้ไข',
          variant: 'warning',
          method: 'edit',
          func: this.updateData,
          disabled: this.method === 'add' || !this.$auth.hasRole(`kioskDevice:${this.kioskDeviceType}:add`)
        },
        {
          text: 'ลบ',
          variant: 'danger',
          method: 'delete',
          func: this.destroyData,
          disabled: this.method === 'add' || !this.$auth.hasRole(`kioskDevice:${this.kioskDeviceType}:add`)
        },
        {
          text: 'รีสตาร์ท',
          variant: 'info',
          method: 'restart',
          func: this.restartDevice,
          disabled: this.method === 'add',
          header: true
        },
        {
          text: 'กลับ',
          variant: 'primary',
          func: this.closeForm,
          header: true,
          align: 'right'
        },
      ]
    }
  },
  methods: {
    dataDetail () {
      this.$apollo.query({
        query: DETAIL_DEVICE(this.templateType),
        variables: {
          kioskDeviceType: this.kioskDeviceType,
          kioskDeviceId: this.kioskDeviceId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {this.setFormData(res.data.device)})
      .catch(err => {
        console.log(JSON.stringify(err,null,2))
        this.$toasted.global.error(err)
      })
      .finally(() => {this.isLoaded = true})
    },
    setFormData (v) {
      Object.keys(this.formData).forEach(key => {
        this.formData[key] = v[key]
      })
    },
    serializeInput (v) {
      return v
    },
    createData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: CREATE_DEVICE(this.templateType),
        variables: {
          kioskDeviceType: this.kioskDeviceType,
          input
        }
      })
      .then(res => {
        this.method = 'info'
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$router.push({
          name: this.$route.name,
          params: {...this.$route.params, kioskDeviceId: res.data.createDevice.id},
          query: {...this.$route.query, redirect: this.listView}
        })
      })
      .catch(this.$toasted.global.error)
    },
    updateData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)

      this.$apollo.mutate({
        mutation: UPDATE_DEVICE(this.templateType),
        variables: {
          kioskDeviceType: this.kioskDeviceType,
          kioskDeviceId: this.kioskDeviceId,
          input
        }
      })
      .then(res => {
        this.setFormData(res.data.updateDevice)
        this.method = 'info'
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    destroyData () {
      this.$apollo.mutate({
        mutation: DESTROY_DEVICE(this.templateType),
        variables: {
          kioskDeviceType: this.kioskDeviceType,
          kioskDeviceId: this.kioskDeviceId
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.closeForm()
      })
      .catch(this.$toasted.global.error)
    },
    restartDevice () {
      this.$apollo.mutate({
        mutation: RESTART_DEVICE(this.templateType),
        variables: {
          kioskDeviceType: this.kioskDeviceType,
          kioskDeviceId: this.kioskDeviceId
        }
      })
      .then(() => {
        this.$toasted.global.success("รีสตาร์ทอุปกรณ์")
      })
      .catch(this.$toasted.global.error)
    },
    closeForm () {
      if (this.$route.query.redirect) {
        if (this.$route.query.redirect === this.$route.name) {
          this.$router.push({name: this.listView})
        } else {
          this.$router.push({name: this.$route.query.redirect})
        }
      } else {
        this.$router.push({name: this.listView})
      }
    },
  },
  created () {
    if (this.kioskDeviceId === 0) {
      this.method = 'add'
      this.isLoaded = true
    } else {
      this.dataDetail()
    }
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
  components: {
    DetailLocation
  }
}
</script>

<style lang="css" scoped>
</style>
