import gql from 'graphql-tag'


export const LIST_DEVICE = (templateType) => gql`query LIST_DEVICE ($kioskDeviceType: String!, $q: FilterInput) {
  devices: listKioskDevice${templateType} (kioskDeviceType: $kioskDeviceType, q: $q) {
    id type code name locations isActive
  }
}`

export const DETAIL_DEVICE = (templateType) => gql`query DETAIL_DEVICE ($kioskDeviceType: String!, $kioskDeviceId: Int!) {
  device: detailKioskDevice${templateType} (kioskDeviceType: $kioskDeviceType, kioskDeviceId: $kioskDeviceId) {
    id type code name locations isActive
  }
}`

export const CREATE_DEVICE = (templateType) => gql`mutation CREATE_DEVICE ($kioskDeviceType: String!, $input: KioskDevice${templateType}Input!) {
  createDevice: createKioskDevice${templateType} (kioskDeviceType: $kioskDeviceType, input: $input) {
    id type code name locations isActive
  }
}`

export const UPDATE_DEVICE = (templateType) => gql`mutation UPDATE_DEVICE ($kioskDeviceType: String!, $kioskDeviceId: Int!, $input: KioskDevice${templateType}Input!) {
  updateDevice: updateKioskDevice${templateType} (kioskDeviceType: $kioskDeviceType, kioskDeviceId: $kioskDeviceId, input: $input) {
    id type code name locations isActive
  }
}`

export const DESTROY_DEVICE = (templateType) => gql`mutation DESTROY_DEVICE ($kioskDeviceType: String!, $kioskDeviceId: Int!) {
  destroyDevice: destroyKioskDevice${templateType} (kioskDeviceType: $kioskDeviceType, kioskDeviceId: $kioskDeviceId) {id type}
}`

export const RESTART_DEVICE = (templateType) => gql`mutation RESTART_DEVICE ($kioskDeviceType: String!, $kioskDeviceId: Int!) {
  restartDevice: restartKioskDevice${templateType} (kioskDeviceType: $kioskDeviceType, kioskDeviceId: $kioskDeviceId)
}`
